<template>
    <div class="content-box">
        <el-card class="my-20">
            <div class="d-flex-center">
                <div class="d-inline-flex-center is-link" @click="$nav.back()">
                    <i class="icon icon-back my-8 mr-8" />
                    <span class="color-666">{{ $t('base.back') }}</span>
                </div>
                <div class="font-16 ml-20 pl-20 mr-auto font-bold color-666 border-left">{{ $t('fund.my_participation') }}</div>
                <el-button type="primary" size="mini" icon="el-icon-refresh" plain @click="fatchRecord()">{{ $t('base.refresh') }}</el-button>
            </div>
        </el-card>
        <div class="mb-50 bg-white box-shadow border-r" v-loading="loading">
            <el-row :gutter="20" type="flex" justify="center" align="middle" class="color-gray px-10 py-15 border-bottom font-12">
                <el-col :span='4'>{{ $t('base.amount') }} (USDT)</el-col>
                <el-col :span='3'>{{ $t('fund.lock_period') }}</el-col>
                <el-col :span='3'>USDT {{ $t('base.profit') }}</el-col>
                <el-col :span='2'>{{ $t('base.coin') }}</el-col>
                <el-col :span='3'>{{ $t('base.coin_profit') }}</el-col>
                <el-col :span='3'>{{ $t('fund.lock_start_time') }}</el-col>
                <el-col :span='3'>{{ $t('fund.lock_end_time') }}</el-col>
                <el-col :span='2' class="text-right">{{ $t('base.state') }}</el-col>
            </el-row>
            <div v-if="record.data.length > 0">
                <el-row v-for="(item, index) in record.data" :key="index" :gutter="20" type="flex" justify="center" align="middle" class="color-333 px-10 py-15 border-bottom font-144">
                    <el-col :span='4'>{{ item.amount }}</el-col>
                    <el-col :span='3'>{{ item.cycle }} {{ $t('fund.days') }}</el-col>
                    <el-col :span='3'>{{ item.usdt_return_rate }}%</el-col>
                    <el-col :span='2'>{{ item.coin }}</el-col>
                    <el-col :span='3'>{{ item.return_rate }}%</el-col>
                    <el-col :span='3'>{{ item.create_time }}</el-col>
                    <el-col :span='3'>{{ item.end_time }}</el-col>
                    <el-col :span='2' class="text-right">
                        <el-tag :type="state[item.state][0]" effect="plain" hit class="bg-t">{{ state[item.state][1] }}</el-tag>
                    </el-col>
                </el-row>
            </div>
            <div v-else>
                <p class="p-50 m-0 text-center">{{ $t('base.no_more') }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: true,
            record: {
                data: []
            },
            state: [
                ['info', this.$t('fund.state.checking')],
                ['primary', this.$t('fund.state.locking')],
                ['success', this.$t('fund.state.finished')],
                ['danger', this.$t('fund.state.fail')],
                ['info', this.$t('fund.state.checking')]
            ]
        }
    },
    created: function() {
        this.fatchRecord()
    },
    methods: {
        fatchRecord: function () {
            this.loading = true
            this.$request.get('coin_fund/buy_log').then(({ data }) => {
                console.log(data)
                this.record = data
                this.loading = false
            })
        }
    }
}
</script>
<style lang="scss" scoped>
//
</style>